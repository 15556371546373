'use strict';

import {tns} from 'tiny-slider/src/tiny-slider';

document.addEventListener('DOMContentLoaded', function() {
  // Get the button element by its id
  var button = document.getElementById('whatsapp');

  // Add a click event listener to the button
  button.addEventListener('click', function() {
    // Define the link URL
    var linkUrl = 'https://wa.me/+5513997657472';

    // Open the link in a new tab/window
    window.open(linkUrl, '_blank');
    
  });

  // Get the button element by its id
  var button = document.getElementById('contact');

  // Add a click event listener to the button
  button.addEventListener('click', function() {
    // Define the link URL
    var linkUrl = 'https://wa.me/+5513997657472';

    // Open the link in a new tab/window
    window.open(linkUrl, '_blank');
    
  });
  

  var anchor = document.getElementById('link');
  anchor.addEventListener('click', function(event) {
    event.preventDefault();

    // Get the target section id from the href attribute
    var targetId = anchor.getAttribute('href').substring(1);

    // Get the target section element
    var targetSection = document.getElementById(targetId);

    // Scroll to the target section with a smooth transition
    targetSection.scrollIntoView({ behavior: 'smooth' });
  });
  
});

if (window.innerWidth < 767) {

	var slider = tns({
		container: '.benefits_list_mobile',
		items: 1,
		center: true,
		loop: true,
		autoplay: true,
		speed: 600,
		autoplayTimeout: 4000,
    mouseDrag: true,
    controls: false,
    nav: true,
    navPosition: true,
    mouseDrag: true,
	});

}

/**
 * import css/sass
 */

import css from '../css/reset.css';
import maincss from '../css/main.css';
